<template lang="pug">
div
  .costs-list-title(
    @click="setActive('Private leasing')"
  )
    span
      i.fas.fa-chevron-right.costs-list-chevron
      span.costs-list-title-text {{ $t("financeForms.privateLeasing") }}
    span.costs-list-price
     span.big {{ totalMonthlyPrice }}
     | &nbsp;&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

  .costs-list-content
    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="true"
    )
    DistanceChoiser(
      :fixedCostsBy="fixedCostsByMonth"
      :showPices="true"
      @setDownPayments="setDownPayments"
    )

    DownPayment(
      v-if="this.useDownPayments && (downPaymentsByDistance.length | 0)"
      :downPayments="downPaymentsByDistance"
      :selectedDistance="selectedDistance"
      :showPrices="true"
    )

    CashPayment(v-if="this.useCashPayments")

</template>

<script>
import CostsMixin from '@/mixins/CostsMixin'
import {mapState} from "vuex";

export default {
  mixins: [ CostsMixin ],
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
    DistanceChoiser: () => import('./../costs-parts/distance'),
    DownPayment: () => import('./../costs-parts/downPayment'),
    CashPayment: () => import('../costs-components/cashPayment'),
  },
  computed: {
    ...mapState('reseller', {useDownPayments: state => state.resellerInfo.settings?.useDownPayments, useCashPayments: state => state.resellerInfo.settings?.useCashPayments}),
  },
  methods: {
    setActive(value) {
      this.$emit('click', value)
    }
  },
}
</script>